import React from 'react';
import { Card } from 'react-bootstrap';
import NumberList from './NumberList';

const CardContent = (props) => {
  const data = props.data;
  return (
    <div>
      <Card.Title><b>Certifikat för godkännande</b></Card.Title>
      <Card.Text className="text-primary" style={{ fontSize: 17 }}>
        {data.org_name}
      </Card.Text>
      <Card.Text style={{ fontSize: 15 }}>Standards</Card.Text>
      <NumberList numbers={data.standards} />
      <Card.Text style={{ fontSize: 15 }}>Revisor: <b>{data.auditor}</b></Card.Text>
      <Card.Text style={{ fontSize: 15 }}>Verifierad av: <b>{data.ca}</b></Card.Text>
      <Card.Text style={{ fontSize: 15 }}>Kan godkännas av: <b>{data.sa}</b></Card.Text>
      <Card.Text style={{ fontSize: 15 }}></Card.Text>
    </div>
  );
};

export default CardContent;
