import React, { useState, useEffect } from 'react';
import { Col, Card, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ShowAlerts from './ShowAlerts';
import CustomSpinner from './CustomSpinner';
import ErrorCard from './ErrorCard';
import CardContent from './CardContent';
import RejectForm from './RejectForm';
import showForm from './showForm';

const RejectCard = () => {
  const { id, role } = useParams();
  let [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  let [error, setError] = useState(false);
  const [msg, setMsg] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const url = `/certificate/${id}`;
      const resp = await fetch(url);
      const data = await resp.json();
      if (resp.status === 200) {
        setData(data);
      } else {
        setStatus(resp.status);
        setMsg(data.message);
        setError(true);
      }
      setLoading(false);
    };

    fetchData();
  }, [id]);

  if (loading) {
    return <CustomSpinner />;
  }

  if (error) {
    return <ErrorCard status={status} msg={msg} />;
  }

  return (
    <div>
      <header className="App-header">
        <Card className="text-center" text="dark" style={{ width: '38rem' }}>
          <Row className="justify-content-md-center">
            <Col>
              <Image src="https://www.qvalify.se/wp-content/uploads/qvalify-logo-bla.png" fluid />
            </Col>
          </Row>
          <Card.Body>
            <CardContent data={data}> </CardContent>
            {showForm(data, role, null) ? (
              <RejectForm id={id} role={role}></RejectForm>
            ) : (
              <ShowAlerts status={200} msg={showForm(data, role, 'msg')}></ShowAlerts>
            )}
          </Card.Body>
        </Card>
      </header>
    </div>
  );
};

export default RejectCard;
