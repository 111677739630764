import React, { useState } from 'react';
import { Card, Form, Col, Button } from 'react-bootstrap';
import CenterView from './CenterView';
import ShowAlerts from './ShowAlerts';

const ApproveForm = (props) => {
  const [pin, setPin] = useState('');
  const [disable, setDisable] = React.useState(false);
  const [status, setStatus] = useState('');
  const [msg, setMsg] = useState('');

  const submit = async (e) => {
    e.preventDefault();
    setDisable(true);
    const url = `/certificate/${props.id}/${props.role}/approve`;
    const resp = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pin }),
    });
    const respData = await resp.json();
    setStatus(resp.status);
    setMsg(respData.message);
    setDisable(false);
  };

  return (
    <div>
      <CenterView>
        <Form onSubmit={submit}>
          <Form.Row>
            <Col>
              <Form.Control
                type="password"
                placeholder="PIN"
                required
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </Col>
          </Form.Row>
          <Card.Text style={{ fontSize: 12 }}>Ange din PIN-kod för att godkänna begäran.</Card.Text>
          <Button disabled={disable} type="submit" value="Submit" variant="primary">
            Godkänna
          </Button>
        </Form>
        <Card.Text> </Card.Text>
      </CenterView>
      <Card.Text> </Card.Text>
      <ShowAlerts status={status} msg={msg} />
    </div>
  );
};

export default ApproveForm;
