import React from 'react';
import CenterView from './CenterView';

function ListItem(props) {
  return <h5>{props.value.standard}</h5>;
}

const NumberList = (props) => {
  const numbers = props.numbers;
  const listItems = numbers.map((number, index) => <ListItem key={index} value={number} />);
  return <CenterView>{listItems}</CenterView>;
};

export default NumberList;
