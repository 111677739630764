import React from 'react';
import { Card } from 'react-bootstrap';
import ShowAlerts from './ShowAlerts';

const ErrorCard = (props) => {
  return (
    <div>
      <header className="App-header">
        <Card className="text-center" text="dark" style={{ width: '38rem' }}>
          <Card.Body>
            <Card.Title>Något gick fel!</Card.Title>
            <ShowAlerts status={props.status} msg={props.msg} />
          </Card.Body>
        </Card>
      </header>
    </div>
  );
};

export default ErrorCard;
