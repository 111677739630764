import React, { Component } from "react";
import './App.css';
import {Route, BrowserRouter as Router } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import ApproveCard from './components/ApproveCard';
import RejectCard from "./components/RejectCard";

class App extends Component {
  render() {
    return (
      <Router>
      <Route path="/approve/:role/:id" component={ApproveCard} />
      <Route path="/reject/:role/:id" component={RejectCard} />
      </Router>
    );
  }
}

export default App;
