import React from 'react';

import { Alert } from 'react-bootstrap';

function ShowAlerts(props) {
  const status = props.status;
  const msg = props.msg;
  console.log(`Status: ${status}`);
  if (status === '') return '';
  if (status === 200) {
    return (
      <Alert variant="success" className="mb-10" style={{ fontSize: 15 }}>
        {msg}
      </Alert>
    );
  }
  return (
    <Alert variant="danger" className="mb-10" style={{ fontSize: 15 }}>
      {msg}
    </Alert>
  );
}
export default ShowAlerts;
