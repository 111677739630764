const showForm = (data, role, msg) => {
  if (!msg) {
    if (data.status === 'certificate_approver_waiting' && role === 'ca') return true;
    if (data.status === 'signing_authority_waiting' && role === 'sa') return true;
    return false;
  }
  if (data.status === 'certificate_approver_waiting' && role === 'sa')
    return 'Certificate is waiting for Certificate Approval teams approval.';
  if (data.status === 'signing_authority_waiting' && role === 'ca')
    return `Certificate is already approved by ${data.ca}.`;
  if (data.status === 'Rejected') return 'Certificate is rejected.';
  return `Certificate is approved by ${data.sa}`;
};

export default showForm;
