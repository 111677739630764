import React from 'react';
import { Spinner } from 'react-bootstrap';
const CustomSpinner = () => {
  return (
    <div>
      <header className="App-header">
        <Spinner animation="border" variant="light" />
      </header>
    </div>
  );
};

export default CustomSpinner;
